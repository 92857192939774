
@font-face {
    font-family: sf-pro-display-black;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: sf-pro-display-black-italic;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: sf-pro-display-bold;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: sf-pro-display-bold-italic;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: sf-pro-display-heavy;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-Heavy.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: sf-pro-display-heavy-italic;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-HeavyItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: sf-pro-display-light;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: sf-pro-display-light-italic;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: sf-pro-display-medium;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: sf-pro-display-medium-italic;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: sf-pro-display-regular;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: sf-pro-display-regular-italic;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-RegularItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: sf-pro-display-semibold;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-Semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: sf-pro-display-semibold-italic;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-SemiboldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: sf-pro-display-thin;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: sf-pro-display-thin-italic;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: sf-pro-display-ultralight;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-Ultralight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: sf-pro-display-ultralight-italic;
    src: url('../../fonts/SFProDisplay/SF-Pro-Display-UltralightItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
}

$sf-pro-display-black: 'sf-pro-display-black';
$sf-pro-display-black-italic: 'sf-pro-display-black-italic';
$sf-pro-display-bold: 'sf-pro-display-bold';
$sf-pro-display-bold-italic: 'sf-pro-display-bold-italic';
$sf-pro-display-heavy: 'sf-pro-display-heavy';
$sf-pro-display-heavy-italic: 'sf-pro-display-heavy-italic';
$sf-pro-display-light: 'sf-pro-display-light';
$sf-pro-display-light-italic: 'sf-pro-display-light-italic';
$sf-pro-display-medium: 'sf-pro-display-medium';
$sf-pro-display-medium-italic: 'sf-pro-display-medium-italic';
$sf-pro-display-regular: 'sf-pro-display-regular';
$sf-pro-display-regular-italic: 'sf-pro-display-regular-italic';
$sf-pro-display-semibold: 'sf-pro-display-semibold';
$sf-pro-display-semibold-italic: 'sf-pro-display-semibold-italic';
$sf-pro-display-thin: 'sf-pro-display-thin';
$sf-pro-display-thin-italic: 'sf-pro-display-thin-italic';
$sf-pro-display-ultralight: 'sf-pro-display-ultralight';
$sf-pro-display-ultralight-italic: 'sf-pro-display-ultralight-italic';

.sf-pro-display-medium { font-family: $sf-pro-display-medium; }
.sf-pro-display-regular { font-family: $sf-pro-display-regular; }
.sf-pro-display-semibold { font-family: $sf-pro-display-semibold; }
.sf-pro-display-thin { font-family: $sf-pro-display-thin; }
.sf-pro-display-light { font-family: $sf-pro-display-light; }
.sf-pro-display-bold { font-family: $sf-pro-display-bold; }