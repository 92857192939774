@use '../helpers/fonts' as *;
@use '../helpers/variables' as *;

.expandedcard_section {
    display: flex;
    justify-content: center;
    position: relative;
}
.expandedcard {

    &_content {
        .off-text {
            font-family: $sf-pro-display-regular !important;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 19px;
            color: rgba(0, 0, 0, 0.75);
        }
    }
    
    &_modal {
        
        height: 100%;
        width: 100%;

        @media (max-width: 768px) {
            height: 100%;
        }
        
        .edit-job {
            position: unset !important;
        }
        
        .ant-modal-content, .ant-modal-body {
            border-radius: 8px !important;
        }

        .jobdetail {
            flex: 1 1;
            border-right: 1px solid $border-blue-light;
            box-sizing: border-box;
            flex-direction: column;
            justify-content: flex-start;
            padding: 12px 19px 10px 0;
            gap: 22px;
            width: 48%;

            .customer-details {
                display: flex;
                gap: 12px;
                flex-direction: column;
            }

            .info-row {
                align-self: stretch;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 5px;
                font-size: 0.875rem;
                color: $black-900;
                min-height: 32px;
                
                .info-label{
                    display: flex;
                    justify-content: flex-start;
                }
                @media (max-width: 670px) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 8px;
                }

                span {
                    font-weight: 600;
                    font-family: $sf-pro-display-regular !important;
                }

                b {
                    font-size: 1.25rem;
                }

                .add-customer-row{
                    display: flex;
                    flex-direction: row;
                    gap: 16px;
                    justify-content: space-between;
                    align-items: center;
                    flex: 1 1 auto;

                    .select-box-bottom-line{
                        width: 100%;
                    }

                    .css-1dbdror{
                        input{
                            background-color: transparent !important;
                        }
                    }

                    .css-loz7pr::after{
                        display: none;
                    }
                    .label-or {
                        font-size: 0.75rem;
                        line-height: 32px;
                        color: rgba($natural-blue, 0.6);
                        font-family: $sf-pro-display-semibold !important;
                    }

                    button{
                        margin-right: 0 !important;
                    }
                }

                .edit-info {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    box-sizing: border-box;
                    gap: 6px;
                    font-size: 0.875rem;
                    color: $bright-gray;

                    a{
                        color: $bright-gray;
                        gap: 6px;
                        span{
                            color: $bright-gray;
                            font-family: $sf-pro-display-regular !important;
                        }
                    }

                    .phone-svg {
                        opacity: 1;
                        height: 10px;
                        width: 10px;justify-items: flex-start;
                        margin-left: 14px;
                    }

                    span {
                        font-family: $sf-pro-display-light !important;
                        border-bottom: 1px solid transparent;
                    }

                    img {
                        display: flex;
                        opacity: 0;
                    }

                    .ant-select-selection-search-input {
                        text-align: left;
                        width: 100%;
                        border-bottom: none;
                    }
                    input{
                        border: none;
                        width: 80px;
                        border-bottom: 1px solid $border-blue-light;
                        text-align: right;
                    }

                    input::placeholder{
                        color: $cloud-gray;
                        line-height: 120%;
                        font-size: 0.875rem;
                    }

                    &:focus-within{
                        img{
                            opacity: 1;
                        }
                    }

                    &:hover {
                        img {
                            opacity: 1;
                        }
                        .value {
                            border-bottom: 1px solid $border-blue-light;
                        }
                    }

                    .edit-content{
                        width: 100%;
                        display: flex;
                        gap: 5px;
                        justify-content: flex-end;
                        flex-wrap: wrap;
                        align-items: flex-end;

                        .model-field{
                            input {
                                min-height: 32px;
                            }
                        }
                    }
                    @media (max-width: 670px) {
                        padding: 0;
                        justify-content: flex-start;
                        width: 100%;
                        img{
                            display: none;
                        }
                        .edit-content{
                            justify-content: flex-start;
                        }
                    }
                }
            }

            @media (min-width: 768px) {
                height: 100%;
            }

            @media (max-width: 1250px) {
                order: 1;
                border-right: none;
            }

            .number, .date {
                font-size: 16px;
                line-height: 28px;
                font-weight: 700;

                @media (max-width: 768px) {
                    font-size: 10px;
                    line-height: 20px;;
                }
            }
            .number {
                color: $black-opacity-6
            }
    
            .edit {
                font-size: 11px;
                line-height: 18px;
                color: $label-color-green;
                letter-spacing: 0.5px;
            }

            .date {
                color: $black-opacity-6;

                .bi-arrow-right {
                    font-size: 14px;
                    opacity: 0.9;
                }
            }

            .name {
                font-size: 20px;
                line-height: 30px;
                color: $black;

                @media (max-width: 768px) {
                    font-size: 12px;
                    line-height: 24px;
                }
            }

            .address {
                font-size: 14px;
                line-height: 20px;
                color: $black-opacity-6;
                @media (max-width: 768px) {
                    font-size: 10px;
                    line-height: 14px;
                }
            }

            .phone {
                font-size: 11px;
                line-height: 18px;
                border: 1px solid #C7D3E6;
                border-radius: 40px;
                padding: 5px;
                color: $dark-gray;

                @media (max-width: 768px) {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }
            }

            .vehicle-details {
                color: $black;
                font-family: $sf-pro-display-regular !important;
                letter-spacing: unset;
            }

            .vehicle {
                margin: auto 0;
                margin-right: 10px;
                font-size: 11px;
                line-height: 14px;
                color: $active-color-blue;
                letter-spacing: 0.3px;

                @media (max-width: 768px) {
                    font-size: 9px;
                }
            }

            .assign-to-section, .status-section, .color-section {
                label {
                    color: $label-color-green;
                    text-transform: uppercase;
                    text-align: center;
                    letter-spacing: 0.5px;
                    font-size: 9px;
                    font-weight: 700;
                }

                .ant-select {
                    width: 100%;
                }

                .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                    height: 34px;
                    border-radius: 3px;
                    align-items: center;

                    .ant-select-selection-placeholder, .ant-select-selection-item {
                        color: $bright-gray;
                        font-size: 11px;
                    }
                }

                .ant-select-arrow {
                    color: $bright-gray;
                    right: 5px !important;
                }
            }

            .assign-to-section {
                .assign-user {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    &-select {
                        @media (min-width: 768px) {
                            width: 85%;
                        }
                    }

                    .right-icon {
                        margin-left: 6px;
                        font-size: 18px;
                        color: $meteorite-gray;
                    }

                    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                        border: 1px solid #808080 !important;
                        background: unset;
                    }
                }
            }

            .status-section .status-item .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                border: 1px solid #808080 !important;
                background: unset;
            }

            .color-section {
                .color-item {
                    width: 100%;

                    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                        border: 1px solid #808080 !important;
                    }
                }
            }

            .footer-section {
                margin-bottom: -5px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (max-width: 768px) {
                    margin-top: 20px;
                    display: none !important;
                }

                .save-section a {
                    background-color: $btn-bg-blue-light;
                    border: 1px solid rgba(1, 69, 157, 0.2);
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    width: fit-content;
                    padding: 0 15px 0 10px;
                    color: $primary;

                    i.bi-check {
                        font-size: 22px;
                    }

                }

                .close-expand-modal {
                    color:$natural-blue;
                    position: unset;
                }

                .job-last-update-date {
                    color: $dark-blue-opacity-7;
                    font-size: 10px;
                    text-transform: uppercase;
                    font-weight: 700;
                }

                .job-last-update-date time {
                    color: $black-opacity-6;
                    font-weight: 600;
                    font-size: 12px;
                }

                .drag_drop_light_text.job-last-update-date time {
                    color: $white;
                }
            }
        }

        .photomanagement {

            .pdf-icon-wrap {
                img {
                    width: 70%;
                    margin: auto;
                }

                p {
                    font-size: 9px;
                    color: black;
                    margin-top: 8px;
                    text-align: center;
                }
            }

            .ant-divider-horizontal.ant-divider-with-text::after {
                border-top: 1px solid rgba(0, 0, 0, 0.4);
                transform: translateY(47%) !important;

                @media (max-width: 768px) {
                    top: 0;
                }
            }

            .space {

                width: 100%;

                & div > div {
                    position: relative;
                }

                .slick-slide {
                    //width: 100px !important;
                    height: 200px;
                }

                .ant-space-item, .slick-slide {
                    width: 23%;
                    aspect-ratio: 1 / 1;

                    @media (max-width: 1236px) {
                        width: 31%;
                    }

                    @media (max-width: 995px) {
                        width: 48%;
                    }

                    @media (max-width: 995px) {
                        width: 100%;
                    }
                    
                    @media (max-width: 768px) {
                        width: 48%;
                        // height: 48%;
                    }
                }

                .ant-space-item > div {
                    width: 100%;
                    height: 100%;
                    background: rgba(255, 255, 255, 0.4);

                }

                .ant-space-item div > div {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    transition: transform .135s cubic-bezier(0,0,.2,1);
                }

                .zoom-out {
                    // transform: translateZ(0px) scale3d(0.82, 0.82, 1);
                }

                .no-background {
                    background-image: none !important;
                }

                .full-container {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }

                .check-wrap {
                    background: linear-gradient(-1.16deg, $transparent-black-1, $transparent-smoky-black 70%);
                    height: 100%;
                    position: absolute;
                    transition: opacity .135s cubic-bezier(0,0,.2,1);
                    width: 100%;
                    top: 0;
                    opacity: 0;
                    display: flex;
                    flex-direction: column;
                    padding: 4px;

                    .check-back {
                        display: none;
                        fill: white;
                        position: absolute;
                        top: 5px;
                        left: 5px;
                    }

                    .form-check-input:checked {

                        & + label .check-prev {
                            fill: rgb(26,115,232);
                            fill-opacity: 1;
                            opacity: 1;
                        }

                        & + label .check-back {
                            display: block;
                        }
                    }

                    .check-prev {
                        color: $white;
                        fill: $white;
                        fill-opacity: 0.4;
                        cursor: pointer;
                        position: absolute !important;
                        left: 5px;
                        top: 5px;

                        &:hover {
                            fill-opacity: 1;
                        }
                    }
                }

                .image-container{
                    position: relative;
                    cursor: pointer;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    transition: transform 0.2s ease;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .selected-img {
                    height: 100%;
                    width: 100%;
                    border-radius: 2px;
                    transition: transform 0.3s ease, opacity 0.3s ease;
                }

                .image-container.selected{
                    background-color: $gray-white;
                    @supports (-webkit-transform: translateZ(0)) {
                        .selected-img {
                          transform: scale(0.88); 
                        }
                    }
                }

                .image-container:hover .check-wrap,
                .image-container.selected .check-wrap  {
                    opacity: 1;
                }

                .zoom-icon {
                    color:$white;
                    fill: $white;
                    height: 22px;
                    width: 22px;
                }
            }

            &-button {
                width: initial;

                @media (max-width: 768px) {
                    width: 100%;
                }
            }
        }
    }
}

.expandedcard_modal {
    min-height: 100%;
    overflow-y: auto;
    height: auto;
    max-height: calc(100vh - 100px);
}

.lightbox-iframe{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    z-index: 10000;
    background-color: white;
}

.photomanagement_modal {
    .ant-modal-content, .ant-modal-body {
        border-radius: 8px !important;
        background: #f5f5f5;
        box-shadow: 0px 2px 41px rgba(40, 41, 61, 0.23), 0px 4px 10px rgba(34, 34, 35, 0.35);
    }
}

.vehicle-uploader {
    .title {
        font-size: 16px;
    }

    .drag-drop {
        object-fit: contain;
    }

    .drag-area-color-blue {
        background-color: $weathered;
    }

    .thumb-image {
        width: 40px;
        border-radius: 2px;
    }

    .thumb {
        .thumb-details {
            width: 85% !important;
        }

        .thumb-name {
            width: 98%;
            gap: 7px;
        }

        .image-title {
            font-size: 12px;
            line-height: 18px;
            width: 87%;
        }

        .image-size {
            font-size: 10px;
            line-height: 16px;
            color: $red-dark-gray;
        }

        &:first-child {
            padding-top: 50px !important;
        }

        &:last-child {
            padding-bottom: 50px !important;
        }

        @media (max-width: 550px) {
            .thumb-details {
                width: 75% !important;
            }
        }
        @media (max-width: 350px) {
            .thumb-details {
                width: 72% !important;
            }
        }
    }
}

.ant-progress .ant-progress-bg {
    height: 5px !important;
}

.ant-normal .ant-progress-bg {
    background-color: $btn-bg-blue-dark !important;
}

.col-divider {
    border-top: 1px solid #C7D3E6;
    width: 90%;
    margin-top: 10px;
}