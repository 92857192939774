@use '../helpers/fonts' as *;

.ant-layout-sider-dark {
    background-color: #f9f9f9;
}

.ant-layout-sider-collapsed {
    background-color: #F1F4F9;
}

.action_wrap {
    position: relative;
    right: 0;
    top: 64px;
    width: 100%;
    background-color: #F1F4F9;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .close_arrow {
        width: 14px;
        cursor: pointer;

        &_left {
            transform: rotate(180deg);
        }
    }

    .title {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        font-family: $sf-pro-display-semibold;
        transform: rotate(-90deg);
        width: max-content;
        position: relative;
        top: 50px;
    }

    .short_bar {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100vh - 64px);
        cursor: pointer;
    }

    .quick_board {
        background-color: #f9f9f9;
        top: 0;
        width: 100%;
        padding: 20px;

        .input-group {
            border-bottom: 1px solid #5D6F88;
            border-radius: 0;

            .form-control {
                font-style: italic;
            }
        }

        .search_board {
            background-color: #f9f9f9;
            position: absolute;
            top: 62px;
            width: 100%;
            box-shadow: 9px 0px 37px rgba(0, 0, 0, 0.23);

            .ant-list-header {
                background-color: #f9f9f9;
            }

            .ant-spin-nested-loading {
                background-color: #fff;
            }

            .ant-list-bordered.ant-list-sm .ant-list-header, .ant-list-bordered.ant-list-sm .ant-list-footer {
                padding: 3px 16px !important;
                font-size: 12px !important;
                color: #2772d6;

                .search_board_header {
                    font-family: $sf-pro-display-medium !important;
                }
            }

            .ant-list-bordered.ant-list-sm .ant-list-item {
                font-size: 13px;
                padding: 8px 16px;
            }

            .close_arrow_left {
                width: 11px;
            }

            .detail {
                display: flex;
                font-size: 10px;
                color: #2772d6;
                font-family: $sf-pro-display-regular !important;

                img {
                    margin-left: 2px;
                }
            }

            &_footer {
                background-color: #f6faff;

                button {
                    color: #2772d6;
                }
            }
        }


    }
}

.search-form {
    background: #F6FAFF;
    padding: 0 15px;
    width: 340px;
    max-width: 340px;

    .search-input {
        color: #212121;
        font-weight: 400;
        font-size: 16px;
    }
    .search-input::-webkit-input-placeholder {
        color: #212121;
        font-size: 14px;
    }
    .clear-search.modal-close {
        top: unset;
        width: 12px;
        position: unset;
    }
}

.search_board {
    min-width: 340px;
    margin-top: 1px;
    z-index: 99;
    background-color: white;
    box-shadow: 0px 2px 4px rgb(40 41 61 / 4%), 0px 8px 16px -2px rgb(96 97 112 / 16%);

    .ant-list-header {
        background: rgba(243, 243, 243, 0.6);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 0px 5px 10px;
        border-bottom: unset;

        .search_board_header {
            color: #01459D;
            font-size: 10px;
            font-family: $sf-pro-display-medium !important;
        }
    }

    .ant-list-split .ant-list-item {
        border-bottom: 1px solid #f0f0f0;
    }

    .ant-list-item {
        .search-item {
            color: #000000;
            font-weight: 300;
            font-size: 12px;
        }

        .detail {
            font-size: 10px;
            color: #085ED0;
            font-weight: 500;
            display: flex;
            gap: 5px;

            span.arrow {
                width: 10px;
                margin-left: 2px;
                img {
                    width: 100%;
                }
            }
        }
    }

    .search_board_footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #F6FAFF;
        justify-content: space-between;
        padding: 10px 20px;

        .add_new {
            color: #085ED0;
            font-size: 12px;
            font-family: $sf-pro-display-medium !important;
            padding: 0;

            .plus {
                font-size: 20px;
                margin-left: 5px;
            }
        }
    }
}