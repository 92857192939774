@use '../helpers/variables' as *;
@use '../helpers/fonts' as *;

.hel-top-navbar {
    box-shadow: $box-shadow-bottom;
    margin: 0 !important;
    
    .navbar-list { 
        li img {
            width: 25px; 
            height: 25px;
            
            &.avatar-40 {
                width:40px;
                height: 40px;
                border: 2px solid $header-avatar-border;
            }
    
            &.indicator {
                width: initial;
                height: initial;
                position: absolute;
                bottom: 10px;
                right: 5px;
            }
        }
    }

    .select-container {
        max-width: 260px;
    }

    .input-group-text {
        padding: 0 !important;
    }

    .hel-sub-dropdown {
        background: #FFFFFF;
        box-shadow: 9px 0px 37px rgb(0 0 0 / 23%);
    }

    .hel-sub-card {
        padding: 30px 40px;
    }

    .password {
        &-title {
            font-family: $sf-pro-display-light !important;
            color: black;
        }

        &-cancel {
            border: none;
            color: $primary;
            background-color: $btn-bg-blue-light;
            font-size: 13px;
            border-radius: 3px;
        }
        
        &-update {
            background-color: $btn-bg-blue-dark;
            border-radius: 3px;
            font-size: 13px;

            img{
                width: auto !important;
                height: auto !important;
            }
        }
    }

}
