@use '../helpers/variables' as *;
@use '../helpers/fonts' as *;

.filter-modal-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 1000;
    transform: scaleY(0);
    height: 0;
    transform-origin: top;
    overflow: hidden;
    transition: all 0.3s ease;

    &.open {
        transform: scaleY(1);
        height: fit-content;
        transform-origin: top;
        overflow: hidden;
        transition: all 0.3s ease;
    }
}

.filter-modal {
    padding: 20px 0;
    display: flex;
    height: fit-content;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.modal-content {
}

.action-btn {
    padding: 0 0 0 1rem;
    
    button {
        min-height: 40px;
    }

    @media (max-width: 768px) {
        padding: 1rem 10px 0 10px;
    }
}

.item-modal {
    position: relative;
    border-radius: 3px;
    background-color: $white;
    border: 1px solid $border-blue-light;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    .dot {
        height: 16px;
        width: 16px;
        .dot-inner{
            height: 16px;
            width: 16px;
        }
    }
}

.color-block {
    height: 14px;
    width: 14px;
    border-radius: 1.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btn-apply {
    position: relative;
    border-radius: 2px;
    background-color: $sky;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6.5px 26px;
    box-sizing: border-box;
    font-size: 0.75rem;
    font-weight: 500;
    color: $white;
    border: none;
}

.btn-clear {
    position: relative;
    border-radius: 2px;
    background-color: $btn-bg-blue-light;
    border: 1px solid $weathered;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6.5px 28px;
    font-size: 0.75rem;
    color: $natural-blue;
    min-height: 40px;
}

@media (min-width: 500px) {
    .select-min-width {
        min-width: 150px;
    }
}

@media (max-width: 400px) {
    .modal-content {
        padding: 0 10px;
    }

    .filter-modal {
        margin-right: 0;
    }
}

@media (max-width: 330px) {
.filter-modal{
    flex-direction: column;
    gap: 15px;
}
}